import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ApplicationState } from '../../../store'
import DocumentsThunk from '../../../store/containers/documents/thunk'
import { IDocumentFolder } from '../../../store/containers/documents/types'

import iconEnter from '../../../assets/images/enter.svg'
import ErrorRoute from '../../../config/ErrorRoute'
import {
    CardBody,
    CardButton,
    CardButtonContainer,
    CardIcon,
    CardSubtitle,
    CardTitle,
    Container,
    FolderCard,
    Subtitle,
} from './style'

const SkeletonCard: React.FC = () => (
    <FolderCard>
        <CardIcon>
            <Skeleton circle height={38} width={38} />
        </CardIcon>
        <CardBody>
            <Skeleton width={135} height={24} />
            <Skeleton width={125} height={18} />
            <CardButton>
                <img src={iconEnter} alt='' />
            </CardButton>
        </CardBody>
    </FolderCard>
)

const FolderList: React.FC = () => {
    const dispatch = useDispatch()

    const { folders, loading, error, errorMessage } = useSelector(
        (state: ApplicationState) => ({
            folders: state.documents.documentFolders,
            loading: state.documents.loading,
            error: state.documents.error,
            errorMessage: state.documents.errorMessage,
        })
    )

    useEffect(() => {
        dispatch(DocumentsThunk.loadFolders())
    }, [dispatch])

    if (error) {
        return <ErrorRoute errorMessage={errorMessage} />
    }

    return (
        <>
            <Subtitle divider />
            <Container>
                {loading ? (
                    <>
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                    </>
                ) : (
                    folders.map((folder: IDocumentFolder) => (
                        <Link
                            key={folder.dtpCodigo}
                            to={{
                                pathname: `/docsrh/folder/${folder.dtpCodigo}`,
                                state: { title: folder.titulo },
                            }}
                        >
                            <FolderCard>
                                <CardIcon>
                                    <FontAwesomeIcon
                                        icon={[folder.brand, folder.icon]}
                                    />
                                </CardIcon>
                                <CardBody>
                                    <CardTitle>{folder.titulo}</CardTitle>
                                    <CardSubtitle>{folder.subtitulo}</CardSubtitle>
                                    <CardButton>
                                        <img src={iconEnter} alt='' />
                                    </CardButton>
                                </CardBody>
                            </FolderCard>
                        </Link>
                    ))
                )}
            </Container>
        </>
    )
}

export default FolderList
