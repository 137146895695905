import styled from 'styled-components'

export const Container = styled.div`
    padding: 16px;
`

export const Subtitle = styled.div<{ divider?: boolean }>`
    background-color: ${(props) => props.theme.colors.primary};
    padding: ${(props) => (props.divider ? '5px' : '24px')};

    > h2 {
        font-weight: bold;
        font-size: 18px;
        color: white;
    }
`

export const Card = styled.a`
    position: relative;
    padding: 32px 0 32px 16px;
    background: #ffffff;
    color: ${(props) => props.theme.colors.text};
    border: 2px solid ${(props) => props.theme.colors.primary};
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    height: 72px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 16px;
`

export const CardIcon = styled.div`
    > svg {
        font-size: 1.75em;
        > path {
            fill: ${(props) => props.theme.colors.primary};
        }
    }
`

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

export const CardTitle = styled.h3`
    font-size: 18px;
    font-weight: 700;

    > small {
        font-weight: 400;
    }
`

export const CardSubtitle = styled.h4`
    font-size: 14px;
    font-weight: 400;
`

export const CardButtonContainer = styled.div`
    position: relative;
    height: inherit;
    display: flex;
    flex-direction: row;
    gap: 16px;
`

export const CardButton = styled.a`
    padding: 8px;
    background-color: ${(props) => props.theme.colors.primary}15;
    border: none;

    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    gap: 4px;
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;

    > span {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: ${(props) => props.theme.colors.primary};
    }
    > img, svg {
        height: 13px;
    }
`

export const CardButtonDownload = styled(CardButton)`
    border-radius: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: 'transparent';
`

export const FolderCard = styled(Card)`
    justify-content: flex-start;
    gap: 25px;

    ${CardButton} {
        position: absolute;
        bottom: 0;
        right: 0;

        border-radius: 10px 0;
        > a {
            > img {
                margin-right: unset;
                margin-left: 5px;
            }
        }
    }
`
